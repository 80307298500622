<template>
  <v-form ref="form" @submit.prevent="login" v-model="valid" v-if="showLogin">
    <div class="H6-Primary-Left">Welcome back to</div>
    <div class="H4-Primary-Left">Admin Panel</div>
    <h4 v-show="exit_status">{{ exit_status }}</h4>
    <v-text-field
      label="E-mail"
      v-model="email"
      :rules="emailRules"
      @keydown.space.prevent
    ></v-text-field>
    <v-text-field
      label="Password"
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      :rules="passwordRules"
      :error-messages="errorMessages"
      :append-icon="showPasswordIcon"
      @click:append="showPassword = !showPassword"
    ></v-text-field>
    <v-btn color="primary Button-Selected-On-Primary-High-Emphasis-Center" type="submit"
      >Sign in</v-btn
    >
    <div class="Subtitle-2-Primary-Left" @click="forgotPasword" style="cursor: pointer;">
      Forgot Password?
    </div>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import rules from '@/rules';
import store from '@/store';
import AnalyticsHelperModule from '@/helpers/analytics-helper-module';
import { heap } from '@/helpers/heap';

export default {
  name: 'login',

  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      rules.required('Please enter your email'),
      rules.email('Please enter a valid email'),
    ],
    password: '',
    passwordRules: [rules.required('Please enter your password')],
    showPassword: false,
    errorMessages: [],
    showLogin: true,
  }),

  created() {
    this.email = this.$store.state.adminPanel.loginEmail;
  },

  // Redirects if the user is already logged in
  beforeRouteEnter(to, from, next) {
    if (!store.getters['adminPanel/requireAuth']) {
      next({ name: 'dashboard' });
    }
    next();
  },

  computed: {
    ...mapState('adminPanel', ['user']),
    ...mapGetters('adminPanel', ['getIsLoggedIn']),
    showPasswordIcon() {
      if (this.password.length === 0) {
        return '';
      }
      if (this.showPassword) {
        return 'mdi-eye-off';
      }
      return 'mdi-eye';
    },
    exit_status() {
      return this.$store.state.adminPanel.exit_status;
    },
  },

  watch: {
    email() {
      this.errorMessages = [];
    },
    password() {
      this.errorMessages = [];
    },
  },

  methods: {
    ...mapActions('splitio', ['fetchSplits']),
    ...AnalyticsHelperModule,
    async login() {
      if (this.errorMessages.length > 0) {
        this.errorMessages = [];
        this.valid = true;
      }

      if (!this.valid) {
        return;
      }

      try {
        await this.$store.dispatch('adminPanel/login', {
          email: this.email,
          password: this.password,
        });

        await this.$store.dispatch('adminPanel/init');
        if (this.getIsLoggedIn) {
          await this.fetchSplits({ email: this.user?.email });
        }
        heap().init();
        const runner_roles = this.$store.state.adminPanel.user.permissions.scopes.filter(
          (item) => typeof item === 'string' && item.indexOf('runner_role') > -1,
        );

        if (runner_roles.length > 0) {
          // eslint-disable-next-line no-throw-literal
          throw { message: 'Cannot log into Admin Panel as a Runner', code: 401 };
        }

        // determine if redirect is required
        const path = {};
        if (this.$route.query.redirect) {
          path.path = this.$route.query.redirect;
        } else {
          path.name = 'dashboard';
        }
        this.$router.push(path);
      } catch (error) {
        this.$store.dispatch('adminPanel/setLogout');
        if (error.code === 401) {
          this.errorMessages.push(error.message);
        } else if (error.response?.data?.code === 401.3) {
          this.errorMessages.push("Your username or credentials don't seem to match");
        } else if (error.response?.data?.error) {
          this.errorMessages.push(error.response.data.error);
        } else {
          this.errorMessages.push('Something unexpected has occured. Please contact support.');
        }
      }
    },

    forgotPasword() {
      this.$store.commit('adminPanel/setLoginEmail', this.email);
      this.$router.push({
        name: 'forgot-password',
      });
    },
  },
};
</script>

<style></style>
