/* eslint-disable */

export default {
  required(msg, validValues = []) {
    return (value) => {
      if (value && value.trim) {
        value = value.trim();
      }
      return !!value || value === 0 || validValues.includes(value) || msg;
    };
  },
  requiredObjectField(msg, key) {
    const isObject = (val) => {
      if (val === null || Array.isArray(val)) {
         return false
      }
      return typeof val === 'object';
    };
    return (value) => {
      return (isObject(value) ? !!value[key] : !!value) || msg;
    };
  },
  requiredNonFalsey(msg) {
    return (value) => {
      if (value && value.trim) {
        value = value.trim();
      }
      return !!value || msg;
    };
  },
  requiredUnless(msg, unlessCondition) {
    return (value) => !!unlessCondition || !!value || value === 0 || msg;
  },
  requiredArray(msg) {
    return (value) => {
      return (Array.isArray(value) && value.length > 0) || msg;
    };
  },
  email(msg) {
    return (v) => {
      if (!v) return true;
      return (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v,
        ) || msg
      );
    };
  },
  domain(msg) {
    return (v) => {
      if (!v) return msg;
      return (
        !v ||
        (v &&
          /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/.test(
            v,
          )) ||
        msg
      );
    };
  },
  url(msg) {
    return (v) => {
      if (!v) return msg;
      return (
        !v ||
        (v &&
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            v,
          )) ||
        msg
      );
    };
  },
  postalCode(v) {
    if (!v) return 'Postal Code is Invalid';
    const noSpaceStr = v
      .replace(' ', '')
      .trim()
      .toUpperCase();
    return (
      (noSpaceStr &&
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(noSpaceStr)) ||
      'Postal Code is Invalid'
    );
  },
  zipCode(v) {
    if (!v) return 'Zip Code is Invalid';
    const noSpaceStr = v
      .replace(' ', '')
      .trim()
      .toUpperCase();
    return (noSpaceStr && /^[0-9]{5}(?:-[0-9]{4})?$/.test(noSpaceStr)) || 'Zip Code is Invalid';
  },
  latlong(v) {
    if (!v) return 'Latitude and/or Longitude is Invalid';
    const noSpaceStr = v.replace(' ', '').trim();
    return (
      (noSpaceStr &&
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
          noSpaceStr,
        )) ||
      'Latitude and/or Longitude is Invalid'
    );
  },

  min(number, msg) {
    msg = msg || `Must be greater than ${number}`;
    return (value) => {
      if (value && value.replace) value = value.replace(/,/g, '');
      value = parseFloat(value, 10);
      if (value < number) return msg;
      else return true;
    };
  },

  greaterThan(number, msg) {
    msg = msg || `Must be greater than ${number}`;
    return (value) => {
      if (value && value.replace) value = value.replace(/,/g, '');
      value = parseFloat(value, 10);
      if (value <= number) return msg;
      else return true;
    };
  },

  max(number, msg) {
    msg = msg || `Must be less than ${number}`;
    return (value) => {
      if (value && value.replace) value = value.replace(/,/g, '');
      value = parseFloat(value, 10);
      if (value > number) return msg;
      else return true;
    };
  },
  phoneNumber() {
    return (value) => {
      if (!value) return true;
      return /^\d{10,}$/.test(value) || 'Please enter a valid phone number';
    };
  },

  maxCharacters(number, msg) {
    msg = msg || `Maximum characters is ${number}`;
    return (value = '') => {
      if (value.length <= number) return true;
      return msg;
    };
  },

  maxWords(number, msg) {
    msg = msg || `Maximum words is ${number}`;
    return (value) => {
      if (value && value.replace) value = value.trim();
      const numberWords = (value || '').trim().split(/\s+/).length;
      if (numberWords > number) return msg;
      else return true;
    };
  },

  regex(reg, msg) {
    return (v) => {
      return !!reg.exec(v) || msg || 'Does not match expected format';
    };
  },

  numeric() {
    return (v) => {
      return !!/^[0-9]+$/.exec(v) || 'Please enter numbers only';
    };
  },
  isAlphaNumeric(fieldInput) {
    return /^[a-z0-9]*$/i.test(fieldInput) || 'please use alpha numeric values only';
  },
  isAlphaNumericAndSpecialChars(fieldInput) {
    return (
      /^[a-z0-9!@#$&()\\-`.+,/\"]*$/i.test(fieldInput) ||
      'please use alpha numeric or special character values only'
    );
  },
  allowedTime(time, date, override, msg) {
    const timestamp = new Date(`${date}T${time}`);
    const now = new Date();
    if (timestamp >= now || override) return true;
    return msg;
  },
  wholeNumber() {
    return (value) => {
      if (!value) return true;
      return !!/^[0-9]+$/.exec(parseInt(value)) || 'Please enter whole numbers only';
    };
  },
  wholeNumber() {
    return (value) => {
      if (!value) return true;
      return !!/^[0-9]+$/.exec(value) || 'Please enter whole numbers only';
    };
  },
  exactChars(fieldName, num) {
    return (v) => {
      return v.length === num || `${fieldName} needs to be ${num} characters long`;
    };
  },
  arrayOf(rule) {
    return (v) => {
      // test each element of array with the rule provided
      // and return with error message if any element fails validation
      if (Array.isArray(v)) {
        let isValid = true;
        v.forEach((value) => {
          const testRule = rule(value);
          if (testRule !== true) isValid = testRule;
        });
        return isValid;
      }
      return false;
    };
  },
  isIPAddress(fieldInput) {
    return (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        fieldInput,
      ) || 'please enter valid IP address'
    );
  },
};
